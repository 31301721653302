import * as React from 'react';

import styled from 'styled-components';
import Layout from '../layouts/Layout/Layout';
import { Heading, Section, Seo } from '../components';

interface NotFoundPageProps {
  location: Location;
}

const NotFoundPageWrapper = styled(Section)`
  display: flex;
  height: calc(100vh - 290px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NotFoundPage = ({ location }: NotFoundPageProps) => (
  <Layout location={location}>
    <Seo title="404: Not found" />
    <NotFoundPageWrapper>
      <Heading variant="h1">404: Not Found</Heading>
      <Heading variant="h4">You just hit a route that doesn&#39;t exist... the sadness.</Heading>
    </NotFoundPageWrapper>
  </Layout>
);

export default NotFoundPage;
